<template>
  <section>
      <q-permissions :__PERMISSIONS__="permStatus" :page="'relance'" :index="'index'" />

       <div class="position-relative table-responsive table-base" v-if="permStatus" >
            <b-card>
              <!-- 
                  -
                  -
                  -
                  -
                  -
                  -
                  -
                  -
              -->
              <!-- Right Col: Table -->
              <b-col lg="12" md="12">
                <b-row>
                  <b-col lg="6" md="6">
                    <h3 class="font-weight-bold" style="">{{ stockDetails.libelle }}</h3>
                    <span class="font-weight-bold"> Creer le {{ stockDetails.created_at }}</span>
                    <span> {{ stockDetails.description }} </span>
                  </b-col>
                </b-row>
              </b-col>

              <b-row>
                <!-- User Info: Left col -->
                <b-col lg="12" md="12" class="">
                  <!-- User Stats -->
                  <div
                    class="my-2"
                    style="
                      display: grid !important;
                      grid-template-columns: repeat(3, 1fr) !important;
                      grid-gap: 10px !important;
                      grid-auto-rows: minmax(50px, auto) !important;
                      width: 100% !important;
                    "
                  >
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="ShoppingBagIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0 text-success">
                          {{ stockDetails.qte_restante }}
                        </h5>
                        <small> Article restant </small>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="DollarSignIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0 text-success">
                          {{ formatter(parseInt(stockDetails.prix_vente)) }}
                        </h5>
                        <small> Prix unitaire </small>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="DollarSignIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0 text-success">
                          {{
                            formatter(
                              parseInt(stockDetails.qte_restante * stockDetails.prix_vente)
                            )
                          }}
                        </h5>
                        <small> Prix Total </small>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="ShoppingCartIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <div class="d-flex">
                          <h5 class="mb-0 text-success mr-50">
                            {{ stockDetails.total_sale }}
                          </h5>
                          <small> Nombre de vente </small>
                        </div>

                        <div class="d-flex">
                          <h5 class="mb-0 text-success mr-50">
                            {{
                              stockDetails.approvisionnements === undefined
                                ? ""
                                : stockDetails.approvisionnements.length
                            }}
                          </h5>
                          <small> Nombre d'approvisionnement </small>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="DollarSignIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0 text-success">
                          {{ stockDetails.qte_total }}
                        </h5>
                        <small> Quantité de vendu </small>
                      </div>
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <b-avatar variant="light-success" rounded>
                        <feather-icon icon="DollarSignIcon" size="18" />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0 text-success">
                          {{ formatter(stockDetails.total_montant_sale) }}
                        </h5>
                        <small> Nomtant total de vente </small>
                      </div>
                    </div>
                  </div>
                </b-col>

                <!-- 
                  -
                  -
                  -
                  -
                  -
                  -
                  -
                  -
              -->

                <b-row class="mx-1">
                  <b-col
                    class="d-flex align-items-center mr-2 bg-warning p-1"
                    style="border-radius: 8px !important"
                  >
                    <div class="">
                      <h1 class="text-white">{{ sts.current }}%</h1>
                    </div>
                    <div class="ml-1">
                      <h5 class="mb-0 text-light">Statistique des ventes</h5>
                      <small> Total montant vendu </small>
                    </div>
                  </b-col>

                  <!-- 
                    -
                    -

                  -->
                  <b-col
                    class="d-flex align-items-center mr-2 bg-info p-1"
                    style="border-radius: 8px !important"
                  >
                    <div class="">
                      <h1 class="text-white">{{ "0" }}%</h1>
                    </div>
                    <div class="ml-1">
                      <h5 class="mb-0 text-light">Statistique des approvisionnement</h5>
                      <small> Total montant vendu </small>
                    </div>
                  </b-col>

                  <!-- 
                    -
                    -

                  -->
                  <b-col
                    class="d-flex align-items-center mr-2 bg-danger p-1"
                    style="border-radius: 8px !important"
                  >
                    <div class="text-white">
                      <h1 class="text-white">{{ "0" }}%</h1>
                    </div>
                    <div class="ml-1">
                      <h5 class="mb-0 text-light">Alert seuil d'approvisionnement</h5>
                      <small class="text-white"> Total montant vendu </small>
                    </div>
                  </b-col>
                </b-row>
              </b-row>
            </b-card>

            <b-row>
              <div class="col-12">
                <b-card :title="'Les ventes : ' + stockDetails.libelle">
                  <b-card no-body>
                    <b-row class="px-2">
                      <!-- Per Page -->
                      <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
                      >
                        <label>Entrées</label>

                        <v-select
                          v-model="state.perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block ml-50 mr-1"
                        />

                        <b-button variant="primary" :to="{ name: 'gsMyStocks' }">
                          <feather-icon icon="PlusIcon" class="mx-auto" />
                          Nouvelle vente
                        </b-button>
                      </b-col>

                      <!-- Search -->

                      <b-col cols="12" md="6" class="mt-1">
                        <div class="d-flex align-items-center justify-content-end">
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              v-model="state.filtre"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : code de vente, type, prix, date..."
                            />
                          </b-input-group>
                        </div>
                      </b-col>
                    </b-row>

                    <q-loader-table
                      :success="state.success"
                      :empty="state.empty"
                      :warring="state.warring"
                    />

                    <b-table
                      hover
                      responsive
                      primary-key="id"
                      :per-page="state.perPage"
                      :current-page="state.currentPage"
                      :items="saleDetails"
                      :fields="tableColumns"
                      :filter="state.filter"
                      show-empty
                      empty-text="Aucun article enregistré"
                      class="bg-white text-left"
                      v-if="state.success === true"
                    >
                      <template #cell(qte_un_article)="data">
                        <div class="py-1">
                          <span> {{ data.item.qte_un_article }} </span>
                        </div>
                      </template>
                    </b-table>

                    <!-- Les boutons de pagination -->

                    <div class="mx-2 mb-2 pb-1">
                      <b-row>
                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                          <span class="text-muted"></span>
                        </b-col>

                        <!-- Pagination -->

                        <b-col
                          cols="12"
                          sm="6"
                          class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                          <b-pagination
                            v-model="state.currentPage"
                            :total-rows="saleDetails.length"
                            :per-page="state.perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>

                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-card>
              </div>
            </b-row>

    <!-- 
    -
    -
    --
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -->
        <b-card :title="'Les approvisionnements : ' + stockDetails.libelle">
          <b-row class="px-2">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
            >
              <label>Entrées</label>

              <v-select
                v-model="state.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />

              <b-button variant="primary" :to="{ name: 'gsMyStocks' }">
                <feather-icon icon="PlusIcon" class="mx-auto" />
                M'approvisionner
              </b-button>
            </b-col>

            <!-- Search -->

            <b-col cols="12" md="6" class="mt-1">
              <div class="d-flex align-items-center justify-content-end">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="state.filtre"
                    class="d-inline-block mr-1"
                    placeholder="Rechercher par : code de vente, type, prix, date..."
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>

          <!-- <q-loader-table
          :success="state.success"
          :empty="state.empty"
          :warring="state.warring"
          v-if="state.success === true"
        /> -->

          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="state.perPage"
            :current-page="state.currentPage"
            :items="stockDetails.approvisionnements"
            :fields="tableColumnsApprovisionnements"
            :filter="state.filter"
            show-empty
            empty-text="Aucun article enregistré"
            class="bg-white text-left mt-2"
          >
            <template #cell(created_at)="data">
              <div class="py-1">
                <span> {{ data.item.created_at }} </span>
              </div>
            </template>
          </b-table>
          <!-- stockDetails.approvisionnements -->

          <!-- Les boutons de pagination -->

          <div class="mx-2 mb-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>

              <!-- Pagination -->

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="state.currentPage"
                  :total-rows="[].length"
                  :per-page="state.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
    <!-- 
    -
    -
    --
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -
    -->
    {{ OnRealTime }}
       </div>
  </section>
 
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import vSelect from "vue-select";
import moment from "moment";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qDecrypted, qDecryptedJson } from "@/services/qeCrypt";
import VueApexCharts from "vue-apexcharts";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import URL from "../../request";
import QPermissions from "../../access/qPermissions.vue";

export default {
  components: {
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    QLoaderTable,
    vSelect,
    VueApexCharts,
    AppEchartBar,
    QPermissions
  },
  setup(props, { root }) {
       const permStatus = computed(() => {
      return root.$store.state.qPermissions.statuts;
    });
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const perPageOptions = ["30"];

    const stockDetails = ref([]);
    const saleDetails = ref([]);
    const tableColumnsApprovisionnements = ref([
      {
        key: "code",
        label: "Code",
      },
      {
        key: "libelle",
        label: "Libelle",
      },
      {
        key: "date_appro",
        label: "date d'approvisionnement",
      },
      {
        key: "created_at",
        label: "Date d'ajout",
      },
    ]);
    const tableColumns = reactive([
      {
        key: "code_facture",
        label: "N de vente",
      },
      {
        key: "prix_vente",
        label: "Prix de vente",
      },
      {
        key: "qte_un_article",
        label: "Quantité",
      },
      {
        key: "prix_total",
        label: "Prix total",
      },
      {
        key: "created_at",
        label: "Date de création",
      },
    ]);
    const GIVE = ref(false);
    const supplyDetails = ref([]);
    const sts = reactive({
      current: 0,
      pass: 0,
    });

    onMounted(() => {
      stockDetails.value = qDecryptedJson(localStorage.getItem("stockDetails"));
      getStockDetails(stockDetails.value.id);
    });

    const OnRealTime = computed(() => {
      if (GIVE.value) {
        onRealTimeData();
      }
    });

    const getStockDetails = async (idOfStock) => {
      try {
        const { data } = await axios.get(URL.INVENTORY_LIST);
        const _sales = [];
        console.log(data);
        if (data) {
          GIVE.value = true;

          const __saleDetails = data.pivot.filter((item) => {
            return item.article_id === idOfStock;
          });

          stockDetails.value.total_sale = __saleDetails.length;

          console.log("__saleDetails : ", __saleDetails);
          stockDetails.value.qte_total = 0;
          stockDetails.value.total_montant_sale = 0;

          for (let i = 0; i < __saleDetails.length; i++) {
            const sale = __saleDetails[i];

            //Number Total of sale
            stockDetails.value.total_montant_sale = parseInt(
              stockDetails.value.total_montant_sale + sale.prix_total
            );

            // Slef
            sale.created_at = moment(sale.created_at).format("DD-MM-YYYY");
            sale.prix_total = formatter(parseInt(sale.prix_total));
            sale.prix_vente = formatter(parseInt(sale.prix_vente));
            sale.code_facture = `${sale.code_facture}`.replace("FAC", "VEN");

            stockDetails.value.qte_total = parseInt(
              stockDetails.value.qte_total + sale.qte_un_article
            );

            // Push to _sales
            _sales.push(sale);
          }

          /**
           *  STATISTICS
           */

          const getDataToStastisc = _sales.map((item) => {
            return {
              qte_total: item.qte_un_article,
              saleDateMonth: item.created_at.split("-")[1],
              saleDateYear: item.created_at.split("-")[2],
            };
          });

          // Data Info and variables
          const nowMonth = moment().format("MM");
          const nowYear = moment().format("YYYY");
          let _stsQteSalePass = 0;
          let _stsQteSaleCurrent = 0;
          let StsCurrent = 0;
          let StsPass = 0;

          // filter in sales data to get current month and year
          const getDataToStastisc_with_paccuracy = getDataToStastisc.filter((item) => {
            return nowMonth === item.saleDateMonth && item.saleDateYear === nowYear;
          });

          // filter in sales data to get pass month and year
          const getDataToStastisc_with_paccuracy_pass = getDataToStastisc.filter(
            (item) => {
              return nowMonth === item.saleDateMonth - 1 && item.saleDateYear === nowYear;
            }
          );

          // Calcul the qte of sale in pass month
          getDataToStastisc_with_paccuracy_pass.forEach((item) => {
            _stsQteSalePass += item.qte_total;
          });

          // Calcul the qte of sale in current month
          getDataToStastisc_with_paccuracy.forEach((item) => {
            _stsQteSaleCurrent += item.qte_total;
          });

          StsPass = (_stsQteSalePass * 100) / (_stsQteSalePass + _stsQteSaleCurrent);
          sts.current =
            (_stsQteSaleCurrent * 100) / (_stsQteSalePass + _stsQteSaleCurrent);
          console.log("StsCurrent : ", _stsQteSalePass, _stsQteSaleCurrent, StsCurrent);

          const _statistics = [];

          // const _filtreByMonthAndYear = getDataToStastisc

          /**
           *
           *
           *
           */

          console.log(
            "getDataToStastisc : ",
            getDataToStastisc,
            getDataToStastisc_with_paccuracy,
            getDataToStastisc_with_paccuracy_pass
          );

          // Set saleDetails
          saleDetails.value = _sales;
          stockDetails.value.approvisionnements.forEach((item) => {
            item.date_appro = moment(item.date_appro).format("DD-MM-YYYY");
            item.created_at = moment(item.created_at).format("DD-MM-YYYY");
          });
        }
      } catch (error) {
        state.warring = true;
        state.loading = false;
        state.empty = false;
        state.success = false;
        console.log(error);
      }
    };

    

    const onRealTimeData = async () => {
      if (GIVE.value === true) {
        saleDetails.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    return {
      state,
      saleDetails,
      tableColumns,
      OnRealTime,
      perPageOptions,
      stockDetails,
      supplyDetails,
      formatter,
      sts,
      tableColumnsApprovisionnements,
      permStatus,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
