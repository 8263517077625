<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center border"
    style="height: 60vh"
    v-if="!__PERMISSIONS__"
  >
    <span style="font-size: 30px; width: 60% !important" class="text-center">
      Vous n'avez pas la permission pour accéder à cette page.</span
    >
    <span
      class="text-uppercase mt-50 text-warning text-decoration-underline"
      style="font-weight: 900; font-size: 12px"
      >Veillez contacter votre administrateur.</span
    >
  </div>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api";
import usePerm from "@/middleware/permissions";
export default {
  props: {
    __PERMISSIONS__: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
    },
    index: {
      type: String,
    },
  },
  setup(props, { root }) {
    onBeforeMount(async () => {
      const { isAccess } = await usePerm.__PERMISSION__(props.page, props.index);
      root.$store.commit("qPermissions/PERMISSIONS", isAccess);
    });
    return {};
  },
};
</script>

<style lang="css" scoped></style>
