import { qDecryptedJson } from "@/services/qeCrypt";

 class usePerm {
    getAllPermissions = qDecryptedJson(localStorage.getItem("qPerm"))

    constructor() {
        this.getAllPermissions;
    }

    async getPermissions(isAccessName, nextAccess) {
        const targetPermission = [];
        let isAccess = false
        this.getAllPermissions.forEach(perm => {
           
            const namePermission = perm.name.split("-");
            if (isAccessName === namePermission[1] && namePermission[0] === nextAccess) {
                targetPermission.push(perm);
                isAccess = true;
            }
        });
        return { toAccess: targetPermission, isAccess: isAccess };
    }

    async __PERMISSION__(route, nextAccess) {
        return await this.getPermissions(route, nextAccess);
    }
}

    export default new usePerm();